import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FloatingButton = props => {
  return (
      <a href={props.to} target="_blank" rel="noopener noreferrer" className={`floating-button ${props.className}`}>
        <FontAwesomeIcon
          icon={['fab', 'whatsapp']}
          className="icon"
          size="lg"
        />
      </a>
  );
};

export default FloatingButton;
