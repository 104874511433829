import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import { NavLink, Nav, Container, Row, Col } from "reactstrap";

const DefaultFooter = () => {
  return (
    <footer className="site-footer section bg-dark text-contrast edge top-left">
      <Container className="py-3">
        <Row className="row gap-y text-center text-md-left">
          <Col md="4" className="mr-auto">
            <img
              src={require("assets/img/pdm_icon.png")}
              alt=""
              className="logo"
            />
            <p>
                <span>PDM Desenvolvimento de Sistemas LTDA</span>
                <br/>
                <span>CNPJ: 39.665.721/0001-56</span>
                <br/>
                <br/>
                <a href="/docs/pdm-politica-privacidade.pdf" target="_blank">Política de privacidade</a>
            </p>
          </Col>

          <Col md="2">
            <h6 className="py-2 small">Siga nossas redes sociais</h6>

            <nav className="nav justify-content-around">
              <a
                href="https://www.facebook.com/pdm.devs"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-circle btn-md brand-facebook"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} />
              </a>
              <a 
              href="https://www.instagram.com/pdm.dev/" 
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-circle btn-md brand-instagram"
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
            </nav>
          </Col>
        </Row>

        <hr className="mt-5 op-5" />

        <Row className="small">
          <Col md="4">
            <p className="mt-2 mb-0 text-center text-md-left">
              © {new Date().getFullYear()}
              {' '}
              <a
                href="https://pdm.dev"                
                rel="noopener noreferrer"
              >
                PDM
              </a>
              {' '} Todos direitos reservados
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default DefaultFooter;
