import React from "react";
import "../../assets/sass/developer.scss";
import { Row, Col } from "reactstrap";
import ProcessCard from "./components/ProcessCard";

import Fade from "react-reveal/Fade";

const processStepsData = [
  {
    img: require("assets/img/pdm_process/01-levantamento.png"),
    title: "1. Levantamento",
    description: "É a primeira e uma das etapas mais importantes dentro do processo, nela buscamos entender a problemática e a real necessidade do nosso cliente.",
  },
  {
    img: require("assets/img/pdm_process/02-prototipacao.png"),
    title: "2. Prototipação",
    description: "Começamos a construção das primeiras telas, dando uma ideia de como ficará a solução, criando assim um protótipo.",
    isRightPosition: true
  },
  {
    img: require("assets/img/pdm_process/03-codificacao.png"),
    title: "3. Codificação e Testes",
    description: "Etapa em que se inicia o desenvolvimento da solução juntamente com o nosso time de tecnologia e qualidade do produto.",
  },
  {
    img: require("assets/img/pdm_process/04-validacao.png"),
    title: "4. Validação",
    description: " Identificamos nesta etapa se a solução desenvolvida está em conformidade com a necessidade do nosso cliente.",
    isRightPosition: true
  },
  {
    img: require("assets/img/pdm_process/05-treinamento.png"),
    title: "5. Treinamento",
    description: "Somente com um treinamento adequado é que é possibilitado e garantido que os usuários utilizem a ferramenta de forma adequada, e que conheçam todas as funcionalidades existentes.",
  },
  {
    img: require("assets/img/pdm_process/06-entrega.png"),
    title: "6. Entrega",
    description: "Por fim, é realizada a entrega da solução para o cliente, além de toda documentação gerada, disponibilizando-a para uso.",
    isRightPosition: true
  },
];

const Process = () => {
  return (
    <section class="process-session">
      <Col>
        <Row className="process-container text-center">
          <h2 class="heading-line" style={{ textAlign: "center" }}>
          Conheça as nossas etapas de Desenvolvimento <br/>de Software:
          </h2>
        </Row>
        {
          processStepsData.map(({ img, title, description, isRightPosition }) => {
            return (
              <Fade bottom key={title}>
                <Row className="content">
                  <ProcessCard
                    isRightPosition={!!isRightPosition}
                    img={img}
                    title={title}
                    description={description}
                  />
                </Row>
              </Fade>
            );
          })
        }
      </Col>  
    </section>
  );
};

export default Process;
