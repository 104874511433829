import classNames from "classnames/bind";
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Container,
  Nav, 
  NavbarBrand,
  NavItem,
  NavLink
} from "reactstrap";

class DefaultNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarExpanded: false,
      toggleDropdown: false,
    };

    this.navbar = React.createRef();

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState(prevState => ({
      navbarExpanded: !prevState.navbarExpanded
    }));
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const navigation = this.navbar.current;
    const navTop = navigation.offsetTop + navigation.offsetHeight;

    if (window.scrollY >= navTop) {
      navigation.classList.add("navbar-sticky");
    } else {
      navigation.classList.remove("navbar-sticky");
    }
  };

   changeToggleDropdown = () => {
    this.setState({ toggleDropdown:!this.state.toggleDropdown })   
  }

  render() {    
    const useOnlyDarkLogo = this.props.useOnlyDarkLogo;
    const routeContactMap = {
      '/': '#contactpdm',
    }   
    
    const  getRouteName = () => window.location.pathname

    return (
      <nav
        ref={this.navbar}
        className={classNames(
          "navbar navbar-expand-md main-nav navigation fixed-top sidebar-left",
          { "navbar-expanded": this.state.navbarExpanded }
        )}
      >
        <Container>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleNavbar}
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>

          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            {useOnlyDarkLogo ? (
              <img
                alt="..."
                src={require("assets/img/pdm_icon.png")}
                className="logo logo-sticky"
              />
            ) : (
              <>
                <img
                  src={require("assets/img/pdm_icon.png")}
                  alt="Laapp"
                  className="logo logo-sticky d-block d-md-none"
                />
              </>
            )}
          </NavbarBrand>

          <div className="collapse navbar-collapse">
            <div className="sidebar-brand">
              <Link to="/">
                <img
                  src={require("assets/img/pdm_icon.png")}
                  alt="Laapp Template"
                  className="logo"
                />
              </Link>
            </div>

            <Nav className="nav navbar-nav ml-auto" navbar>
              <NavItem>
                <NavLink href="/">Início</NavLink>
              </NavItem>
              {/* <Dropdown nav isOpen={this.state.toggleDropdown} toggle={this.changeToggleDropdown}>
                <DropdownToggle nav caret>
                  Pedimos
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={ () => moveTo("/cd") }><a href="/cd">Cardápio Digital</a></DropdownItem>                 
                  <DropdownItem onClick={ () => moveTo("/QRFolder") }><a href="/QRFolder">QR Folder</a></DropdownItem>
                  <DropdownItem onClick={ () => moveTo("/pdm") }><a href="/pdm">PDM Development</a></DropdownItem>
                </DropdownMenu>
              </Dropdown> */}
              <NavItem>
                <NavLink onClick={ (event) => {
                  this.toggleNavbar()
                  event.preventDefault();
                  window.location.href = routeContactMap[getRouteName()]
                }} href="">Contato</NavLink>
              </NavItem>
            </Nav>
          </div>
        </Container>
      </nav>
    );
  }
}

export default DefaultNavbar;
