import React from "react";
import "../../assets/sass/cd.scss";
import { Container, Row, Col } from "reactstrap";

const AppsDEV = () => {
  return (
    <section>
      <Container style={{marginBottom:"-150px"}}>
        <Row>
          <Col>
            {" "}
            <div className="container pb-8 bring-to-front">
              <div className="section-heading text-justify" id="box">
                <div className="screen">
                  <img
                    src={require("assets/img/dev_app.jpg")}
                    alt="..."
                    className="prototype"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="container pb-8 bring-to-front">
              <div className="section-heading text-justify" id="box">
                <h2 className="heading-line" style={{ textAlign: "left" }}>
                  Desenvolvemos também aplicativos mobile para seu negócio
                </h2>
                <p className="text-muted lead">
                Ter todas as informações do seu negócio na palma da mão,
                de forma facilitada, significa ganho de tempo e eficiência.
                Desenvolvemos aplicativos para os principais sistemas
                operacionais, Android e iOS, e criamos contas no
                Google Play e na APP Store, para que eles fiquem disponíveis
                nas lojas virtuais.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>    
    </section>
  );
};

export default AppsDEV;
