import React from "react";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Pulse from "react-reveal/Pulse";
import {
  Col,
  Container,
  Nav,
  Row,
  } from "reactstrap";
import "../../assets/sass/developer.scss";
import MoreInfoButton from "components/MoreInfoButton";
import { HashLink } from 'react-router-hash-link';


const animations = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 2000, delay: 500 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
];

const Heading = ({ formAction }) => {
  const AUTO_MESSAGE = "Eu tenho interesse no desenvolvimento de um software."

  return (
    <header className="header alter-header-section1 section text-contrast">
      <div className="shapes-container">
        {animations.map((el, i) => (
          <Fade bottom duration={el.duration} delay={el.delay} key={i}>
            <div className="shape shape-animated shape-diagonal" key={i} />
          </Fade>
        ))}
      </div>

      <Container className="container">
        <Row>
          <Col md="5">
            <img
              src={require("assets/img/logo_white.svg")}
               alt="..."
              style={{width: "70%", alignItems: "center"}}
            />
            <p className="lead mb-0">
            Contribuímos e automatizamos as tarefas mais simples e mais
              complexas do seu negócio. O nosso time conta com especialistas que além 
              de orientar, desenvolvem as melhores soluções. 
            </p>
            <Nav className="mt-0" tag="nav">
              <HashLink smooth to="/#contactpdm">
                <MoreInfoButton
                  className="btn btn-rounded btn-primary mr-2 mr-md-5"
                  text="Quero saber mais"
                  onClick={() => formAction(AUTO_MESSAGE)}
                />
              </HashLink>
            </Nav>
          </Col>
          
          <Col md="7" className="mt-6 justify-content-center">
            <Zoom bottom duration={800} delay={0}>
              <img
                src={require("assets/img/devices.png")}
                style={{
                  width: "75%", 
                  position: 'absolute', 
                  marginLeft: '40px'
                }}
              />
            </Zoom>
            <Pulse forever duration={3000}>
              <Zoom bottom duration={900} delay={0}>
                <img
                  src={require("assets/img/pdm_process.png")}
                  style={{width: "100%"}}
                  className="mt-5"
                />
              </Zoom>
            </Pulse>
          </Col>          
        </Row>
      </Container>
    </header>
  );
};

export default Heading;