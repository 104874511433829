import DefaultFooter from "components/Footers/DefaultFooter.jsx";
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import React, {createRef, useState} from "react";

import Heading from "../Developer/Heading.jsx";
import AppsDEV from "../Developer/AppsDEV";
import InfoDEV from "../Developer/InfoDEV.jsx";
import Partners from "../Developer/Partners.jsx";
import Lgpd from "../Developer/Lgpd.jsx";
import Process from "../Developer/Process";
import ContactPDM from "../shared/ContactPDM.jsx";

const Developer=()=>{
  const ref= createRef("main");
  const [inputMessageText, setInputMessageText] = useState("")

  return (
    <>
      <DefaultNavbar />
      <main ref={ref}>
        <Heading formAction={setInputMessageText}/> 
        <InfoDEV /> 
        <Lgpd />
        <Partners />
        <AppsDEV />
        <Process />
        <ContactPDM autoMessage={inputMessageText}/>
      </main>
      <DefaultFooter />
    </>
  );
}


export default Developer;
