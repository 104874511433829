import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Developer from "views/landing/Developer.jsx";
import FloatingButton from 'components/FloatingButton'
// global template script file
import "./laapp.js";
import "./assets/sass/global.scss";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Developer {...props} />} />
    </Switch>
    <FloatingButton
      icon={['fas', 'coffee']}
      to="https://api.whatsapp.com/send?phone=5551992844939"
      className="whatsapp"
    />
  </BrowserRouter>,
  document.getElementById("root")

);
