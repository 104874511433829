import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const MoreInfoButton = props => {
  return (
    props.onClick ?  
      <button onClick={props.onClick} className={`more-link ${props.className}`}>
        {props.text}
        <FontAwesomeIcon
          icon={["fas", "long-arrow-alt-right"]}
          className="icon"
        />
      </button>
    : <Link to={props.to} className={`more-link ${props.className}`}>
      {props.text}
      <FontAwesomeIcon
        icon={["fas", "long-arrow-alt-right"]}
        className="icon"
      />
    </Link>
  );
};

export default MoreInfoButton;
