import React from 'react';
import {
	Col,
	Row,
} from "reactstrap";

import "../../../assets/sass/developer.scss";

const ProcessCard = ({ img, title, description, isRightPosition = false }) => {

	const contentInfo = (
		<Col>
			<Row className={`
			${isRightPosition && "justify-content-end"}
		`}>
				<span 
					class="btn-primary rounded-pill shadow-box text-dark bold py-2 px-4"
					style={{backgroundColor: `${isRightPosition ? "#7818F1" : "#D66EF5"}`}}
				>
					<h5 class="my-0 text-white">{title}</h5>
				</span>
				<h5 class={`mt-3 ${isRightPosition && 'text-right'}`}>{description}</h5>
			</Row>
		</Col>
	);

	return (
		<>
			{!isRightPosition && <img src={img} />}
			{contentInfo}
			{isRightPosition && <img src={img} />}
		</>
	);
}

export default ProcessCard;