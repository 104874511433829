import React from "react";
import "../../assets/sass/cd.scss";
import { Container, Row, Col } from "reactstrap";

const Lgpd = () => {
  return (
    <section>
      <Container style={{marginBottom:"-150px"}}>
        <Row>
          <Col>
            {" "}
            <div className="container pb-8 bring-to-front">
              <div className="section-heading text-justify" id="box">
                <div className="screen">
                  <img
                    src={require("assets/img/lgpd.jpeg")}
                    alt="..."
                    className="prototype"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="container pb-8 bring-to-front">
              <div className="section-heading text-justify" id="box">
                <h2 className="heading-line" style={{ textAlign: "left" }}>
                  Estamos de acordo com as melhores técnicas de segurança da informação
                </h2>
                <p className="text-muted lead">
                Estamos atentos aos requisitos da Lei Geral de Proteção de Dados e preocupados em 
                preservar todos os dados que transitam pelos nossos softwares. 
                Gerimos as informações pessoais com segurança e governança robustas e integradas.
	              As soluções desenvolvidas pela PDM fornecem opções de gerenciamento, criando níveis e oportunizando 
                a delimitação dos acessos e hierarquia que cada ferramenta exige.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>    
    </section>
  );
};

export default Lgpd;
