import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardTitle,
  CardText,
  } from "reactstrap";
import "../../assets/sass/cd.scss";
import { PenTool, Unlock, Code, CheckCircle } from 'react-feather';
import { redirectToMenu } from "./shared";


const InfoDEV = () => {
  const [qrCode] = useState("");

  const handleOnSubmit = (event) => {
    event.preventDefault();
    redirectToMenu(qrCode);
  };
  return (
    <section style={{marginBottom:"-100px"}}>
       <Container className="container">
        <Row>
          <Col md="6">
            <h1 className="text-contrast display-4 display-md-4">
              <span className="bold black">{"Desenvolvimento de Sistemas"}</span>
            </h1>
            <p className="lead bold">Você idealiza, em conjunto planejamos e a PDM desenvolve</p>

            <p className="lead">
              Desenvolvemos sistemas com foco em criar soluções eficientes,
              para que atendam as demandas, agreguem valor,
              simplifiquem e automatizem os processos do seu negócio.
            </p>
          </Col>

          <Col md="6">
            <Row>
              <Col sm="6">
                <Card body className="card" style={{ backgroundColor: 'transparent'}}>
                <PenTool size={20}  className="camera-icon text-primary"/>
                  <CardTitle className="title" tag="h5">
                    Design
                  </CardTitle>
                  <CardText style={{ marginTop: '0px', textAlign: 'left' }}>
                  A apresentação da sua marca é muito importante para nós,
                  pois ela carrega a sua identidade e gera credibilidade aos seus clientes,
                  pensando nisso, desenhamos todo o seu aplicativo e website.
                  </CardText>
                </Card>
              </Col>
              <Col sm="6">
                <Card body  className="card" style={{ backgroundColor: 'transparent'}}>
                <Code size={20}  className="camera-icon text-primary"/>
                  <CardTitle tag="h5" className="title">
                    Software
                  </CardTitle>
                  <CardText style={{ marginTop: '0px' , textAlign: 'left'}}>
                  Soluções criadas de forma customizada, utilizando as mais
                  modernas e atuais linguagens de programação,
                  para atender as suas necessidades.
                  </CardText>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Card body  className="card" style={{backgroundColor: 'transparent'}}>
                <CheckCircle  size={20}  className="camera-icon text-primary"/>
                  <CardTitle className="title" tag="h5">
                    Qualidade
                  </CardTitle>
                  <CardText style={{ marginTop: '0px' , textAlign: 'left' }}>
                  As soluções são testadas incansavelmente durante o processo de desenvolvimento,
                  para que no momento da entrega ela esteja apta para o uso.
                  </CardText>
                </Card>
              </Col>
              <Col sm="6">
                <Card body  className="card" style={{ backgroundColor: 'transparent'}}>
                <Unlock size={20}  className="camera-icon text-primary"/>
                  <CardTitle tag="h5" className="title">
                    Segurança 
                  </CardTitle>
                  <CardText style={{ marginTop: '0px' , textAlign: 'left'}}>
                  Proteger as informações tornou-se imprescindível,
                  tratar os seus dados também, por isso, os algoritmos que utilizamos
                  são modernos e estão em conformidade com a LGPD.
                  </CardText>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default InfoDEV;

