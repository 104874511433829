import React from "react";
import Swiper from "react-id-swiper";
import "swiper/dist/css/swiper.css";

const params = {
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnIteration: false
  },
  slidesPerView: 5,
  spaceBetween: 30
};

const listTech = [
 "graphql",
 "hasura",
 "react",
 "python",
 "flutter",
 "php",
 "html_css_js",
 "postgre",
 "mysql",
 "aws",
 "c++",
 "firebase"
]
 
const Partners = () => {
  return (
    <section class="section">
      <div class="container py-5 border-bottom section-heading text-justify" style={{marginTop:-70}}>
      <h2 className="heading-line">Tecnologias já utilizadas</h2>
        <Swiper {...params}>
          {listTech.map(tech => (
            <div key={tech} class="swiper-slide" style={{textAlign: 'center'}}>
              <img
                src={require(`assets/img/tech/${tech}.png`)}
                class="img-responsive"
                style={{ maxHeight: "60px" }}
              />
            </div>
          ))
          }
        </Swiper>
      </div>
    </section>
  );
};

export default Partners;
